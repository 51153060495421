<script setup lang="ts">
// import TheWelcome from '../components/TheWelcome.vue'
import { useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import onlineResult from '../apis/modules/onlineResult'

import ModalRpt from '@/components/modal/ModalRpt.vue'
import Footer from '@/components/Footer.vue'

const route = useRoute()
const idQrcode = ref('')
const idResponseQuery = ref('')
const msgError = ref('')
const hasResults = ref(false)
const dataResponse = ref<any[]>([])
const idImage = ref('')
const timerInterval = ref()
const codePin = ref('')
const blockPage = ref(false)
const ipRemote = ref('')
const showClickImg = ref(false)

const rptUrl = ref('')

const deviceIdentity = ref('')
const countStudies = ref(0)
const refModalRpt = ref(null)
const showModalRpt = ref(false)

onMounted(() => {
  idQrcode.value = route.params.id[0]
  hasResults.value = false
  deviceIdentity.value = `${getDeviceType()}*${getTypeBrowser()}`
  getRemoteIp().finally(() => {
    setTimeout(() => {
      getDataFromServer()
    }, 1000)
  })

  Swal.fire({
    title: 'Buscando Resultados !',
    html: 'Espere..!',
    timer: 4000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()
      // const timer = Swal.getPopup().querySelector('b')
      // timerInterval.value = setInterval(() => {
      //   timer.textContent = `${Swal.getTimerLeft()}`
      // }, 100)
    },
    willClose: () => {
      clearInterval(timerInterval.value)
    }
  }).then(() => {
    /* Read more about handling dismissals below */
    // if (result.dismiss === Swal.DismissReason.timer) {

    // }
  })

  // console.log(route.params.id)
})

async function getRemoteIp() {
  // Example code to get the client IP address using WebRTC
  await fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then((data) => {
      // console.log(data.ip)
      ipRemote.value = `From Ip:*${data.ip}`
      return ipRemote.value
    })
    .catch((error) => {
      console.error('Error fetching IP:', error)
      ipRemote.value = ''
      return ipRemote.value
    })
}
function getDataFromServer() {
  const params: any = {
    type: 'search-qrcode',
    idParam: `${route.params.id}*${ipRemote.value}*[ SEARCH QRCODE ]*${deviceIdentity.value}`
  }
  onlineResult
    .FindQrcode(params)
    .then((v) => {
      if (v.data.code === 200) {
        idResponseQuery.value = v.data.data
        // console.log(idResponseQuery.value)
        setTimeout(() => {
          getListResponse()
        }, 1000)
        // getPin()
      }
      // ElMessage.success(`获取成功 ${v.num}`)
    })
    .catch((err) => {
      // ElMessage.error(err.message)
      console.log(err)
    })
}

function sendIdRptView(logsView: string) {
  const params: any = {
    type: 'add-ViewRpt-Remote',
    idParam: logsView
  }
  onlineResult
    .FindQrcode(params)
    .then((v) => {
      if (v.data.code === 200) {
        // idResponseQuery.value = v.data.data
        // // console.log(idResponseQuery.value)
        // setTimeout(() => {
        //   getListResponse()
        // }, 1000)
        // // getPin()
      }
      // ElMessage.success(`获取成功 ${v.num}`)
    })
    .catch(() => {
      // ElMessage.error(err.message)
      // console.log(err)
    })
}

const getDate = computed(() => (date: string) => {
  const dt = new Date(date)
  const timeFormate = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
  const resulTime = timeFormate.format(dt)

  const dateFormate = `${dt.getDate()}-${dt.getMonth() + 1}-${dt.getFullYear()}`

  return `${dateFormate} ${resulTime}`
})

const getSex = computed(() => (sex: string) => {
  switch (sex) {
    case 'M':
      return 'MASCULINO'
    case 'F':
      return 'FEMENINO'
    default:
      return 'OTRO'
  }
})

const typeStudy = computed(() => (modality: string) => {
  switch (modality) {
    case 'DX':
      return 'RADIOGRAFIA'
    case 'CR':
      return 'RADIOGRAFIA'
    case 'DR':
      return 'RADIOGRAFIA'
    case 'US':
      return 'SONOGRAFIA'
    case 'CT':
      return 'TOMOGRAFIA'
    case 'MG':
      return 'MAMOGRAFIA'
    case 'MR':
      return 'RESONANCIA'
    case 'SR':
      return 'RESONANCIA'
    default:
      return 'OTRO'
  }
})

function getDeviceType() {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

function getTypeBrowser() {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return ('Opera')
  }
  else if (navigator.userAgent.includes('Edg')) {
    return ('Edge')
  }
  else if (navigator.userAgent.includes('Chrome')) {
    return ('Chrome')
  }
  else if (navigator.userAgent.includes('Safari')) {
    return ('Safari')
  }
  else if (navigator.userAgent.includes('Firefox')) {
    return ('Firefox')
  }
  else if ((navigator.userAgent.includes('MSIE')) || (!!document.documentMode === true)) // IF IE > 10
  {
    return ('IE')
  }
  else {
    return ('unknown')
  }
}

function getListResponse() {
  const params: any = {
    type: 'getlist-response-query',
    idParam: idResponseQuery.value
  }
  onlineResult
    .FindQrcode(params)
    .then((v) => {
      if (v.data.code === 200) {
        const datajson = JSON.parse(v.data.data)
        datajson.forEach((element: any) => {
          const itemjson = JSON.parse(element.result)

          countStudies.value = itemjson.studies.length
          // console.log(itemjson.studies)
          dataResponse.value.push(itemjson)
        })

        // console.log(dataResponse.value)

        if (dataResponse.value.length > 0) {
          hasResults.value = true
          idImage.value = `https://drive.google.com/thumbnail?id=${dataResponse.value[0].imgMedicalCenter}`
          codePin.value = dataResponse.value[0].codePin
          blockPage.value = true

          setTimeout(() => {
            getPin()
          }, 1000)
        }
        else {
          hasResults.value = false
          msgError.value = 'Código Qr Sin Resultados \n.. Favor Reintentar en 5 minutos'
          idImage.value = ''
        }

        // getPin()
      }
      // ElMessage.success(`获取成功 ${v.num}`)
    })
    .catch((err) => {
      // ElMessage.error(err.message)
      console.log(err)
    })
}

function getPin() {
  Swal.fire({
    title: 'Digite su Pin',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: false,
    confirmButtonText: 'Ingresar',
    showLoaderOnConfirm: true,
    isDismissed: false,
    allowOutsideClick: false,
    allowEscapeKey: false

    // preConfirm: async (login:any) => {

    // },
    // allowOutsideClick: () => !Swal.isLoading()
  }).then((result: any) => {
    if (result.isConfirmed) {
      // console.log(result.value)

      // console.log(codePin.value)

      if (result.value === codePin.value) {
        blockPage.value = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Pin Valido..',
          showConfirmButton: false,
          timer: 1500
        })
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Verifica tu Pin',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          getPin()
        }, 2000)
      }

      setTimeout(() => {
        showClickImg.value = true
      }, 2000)
      // Swal.fire({
      //   title: `${result.value.login}'s avatar`,
      //   imageUrl: result.value.avatar_url
      // })
    }
  })
}

async function viewRpt(id: string, url: string) {
  await getRemoteIp()
  showModalRpt.value = false
  await sendIdRptView(`${id}*${ipRemote.value}*[ VIEW REPORT ]*${deviceIdentity.value}`)

  if (url.length < 120) {
    window.open(url, '_blank')
  }
  else {
    setTimeout(() => {
      showModalRpt.value = true
      rptUrl.value = url
      refModalRpt.value.showModal()
    }, 200)
  }

  //
}

async function viewImg(id: string, url: string) {
  await getRemoteIp()

  await sendIdRptView(`${id}*${ipRemote.value}*[ VIEW IMAGE ]*${deviceIdentity.value}`)

  window.open(url, '_blank')
}
</script>

<template>
  <main class="container">
    <div v-if="!blockPage">
      <div class="container">
        <div v-if="hasResults">
          <ModalRpt ref="refModalRpt" :url-load="rptUrl" />
          <div class="row">
            <div class="text-center">
              <div>
                <img
                  :src="idImage"
                  alt="Logo Institution"
                  style="
                  width: 250px;
                  max-width: 250px;
                  height: 150px;
                  background: #dddddd;
                  font-family: sans-serif;
                  font-size: 15px;
                  line-height: 15px;
                  color: #555555;
                  margin: auto;
                  display: block;"
                  class="g-img mt-3"
                >
              </div>
              <h4 class="mb-4 mt-3 capitalize ">
                {{ dataResponse[0].medicalCenter }}
              </h4>

              <div class=" text-start mb-4">
                <h6 class="fw-light fs-6">
                  <span class=" fw-bold ">Nombre : &nbsp; </span>  {{ dataResponse[0].name }} {{ dataResponse[0].lastName }}
                </h6>
                <h6 class="fw-light fs-6">
                  <span class=" fw-bold mr-4 "> Sexo / Edad : &nbsp; </span>   {{ getSex(dataResponse[0].sex) }}  | {{ dataResponse[0].age }} A
                </h6>
                <h6 class="fw-light fs-6">
                  <span class=" fw-bold mr-4 "> Documento : &nbsp; </span>   {{ dataResponse[0].document }}
                </h6>
              </div>
            </div>
            <div class="col-24 mb-5 pb-3   ">
              <div class="container p-2">
                <h5 class=" text-center">
                  RECORD DE ESTUDIOS : {{ countStudies }}
                </h5>
              </div>
              <div class="d-flex justify-content-center flex-wrap gap-3">
                <div v-for="(item, index) in dataResponse[0].studies" :key="index" class="card" style="width: 18rem;">
                  <div v-show="showClickImg" class="containerImg mt-3 rounded-full">
                    <a
                      href="#" @click="viewImg(item.id, item
                        .linkStudy,
                      )"
                    >

                      <img style="width:75%; height: 90%;" class="card-img-top" :src="item.preview" alt="image preview">
                      <div class="centered">
                        <h3 class="">
                          <span class="badge bg-danger text-white">Click Ver imágenes</span>
                        </h3>
                      </div>
                    </a>
                  </div>

                  <div class="card-body">
                    <h5 class="card-title text-center">
                      {{ typeStudy(item.modality) }}
                    </h5>
                    <h6 class=" text-center ">
                      <span class="badge bg-light text-dark">Fecha:</span>
                      <span class="badge bg-info text-dark"> {{ getDate(item
                        .dateStudy) }}</span>
                    </h6>
                    <p class="card-text text-muted text-center">
                      <small>   {{ item
                        .Description }}</small>
                    </p>
                    <div v-if="item.rpts.length === 0" class="text-center">
                      <h6 class="text-danger mt-3">
                        Estudio aún no Reportado
                      </h6>
                    </div>
                    <div v-else class="text-center">
                      <h6 class=" text-gray mt-3 text-muted">
                        [ RESULTADOS ]
                      </h6>
                      <div class="text-center">
                        <button v-for="(itemRpt, index2) in item.rpts " :key="index2" class="btn btn-primary w-100 my-1" @click="viewRpt(itemRpt.id, itemRpt.urlRpt)">
                          {{ itemRpt.name }}<span class="m-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-5">
          <div v-show="msgError !== ''" class="flex justify-center items-center">
            <!-- <h3 class="text-danger text-center">

          </h3> -->
            <div class=" img-fluid  text-center">
              <img class="" width="300rem" src="https://thumbs.dreamstime.com/b/entry-qr-code-prohibited-vector-image-barcode-crossed-out-red-circle-sign-prohibition-isolated-white-236782862.jpg">
            </div>

            <p class="fw-lighter fs-5 text-center mt-5">
              {{ msgError }}
            </p>

            <h6 class="text-center text-muted mt-5">
              Disculpe los inconvenientes.
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="">
        <div class="row">
          <div class="col-24 text-center">
            <img class="img-fluid" src="@/assets/images/blockpage.png">
          </div>
        </div>
        <h3 class="text-danger text-center">
          Contenido bloqueado
        </h3>
      </div>
    </div>
  </main>
  <footer class="footer navbar-fixed-bottom ">
    <!-- Your page footer here... -->
    <div class="my-1">
      <br>

      <Footer />
    </div>
  </footer>

  <!-- <div>
    <h2 class="mt-5 pt-5">
      <Footer />
    </h2>
  </div> -->
</template>

<style lang="css">
main {
   min-height: 90vh;
   height: auto !important;
   height: 100%;
   margin: 0 auto -60px;
}
.containerImg {
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: -30px;

}
/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 15%;

  transform: rotate(-20deg);
    /*rotate: -28deg;*/
    font-size: 10px;
}

.container1{

/* Set rules to fill background */
min-height: 100%;
min-width: 1024px;

/* Set up proportionate scaling */
width: 100%;
height: auto;

/* Set up positioning */
position: fixed;
top: 0;
left: 0;

}
</style>

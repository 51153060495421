<script setup lang="ts">
import { defineExpose, defineProps, onMounted, ref } from 'vue'
import { Modal } from 'bootstrap'

const props = defineProps(['urlLoad'])

defineExpose({
  showModal
})
// const urlLoad = ref('https://cedisa-radx.ddns.net/editor?type=embedded&mode=embedded&fileName=ct8rmjerd9iksetpctigct8rmjerd9iksetpctj020241205094013.docx&userid=uid-0&lang=es&directUrl=false&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJpZFVzZXIyNTQxMTEiLCJzdWIiOiJjdDhybWplcmQ5aWtzZXRwY3RpZ2N0OHJtamVyZDlpa3NldHBjdGowMjAyNDEyMDUwOTQwMTMuZG9jeCIsImV4cCI6MTczMzQyMTYyMSwianRpIjoiNDQ0NDQifQ.aYdNftz69429-8Ilmsuejr3uC01UxVL4JN3x0vRLlgc')
const modal = ref(null)
// const loadRpt = ref(false)
onMounted(() => {
  modal.value = new Modal('#id-of-modal', {})
})

function showModal() {
  setTimeout(() => {
    modal.value.show()
  }, 300)
}

function closeModal() {
  modal.value.hide()
}
</script>

<template>
  <div>
    <div id="id-of-modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Visualización de informe.
            </h5>

            <button type="button" class="btn-close" @click="closeModal" />
          </div>
          <div class="modal-body">
            <div style="width: 100%; height: 75vh">
              <iframe
                class="second-row"
                allowtransparency="true"
                frameborder="0"
                scrolling="no"
                :src="urlLoad"
                width="100%"
                height="100%"
              />
            </div>
          </div>

          <div class="modal-footer">
            <div>
              <button class="btn" @click="closeModal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---->
  </div>
</template>
